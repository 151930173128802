import { Helmet } from 'react-helmet-async';
import { Navigate, useLocation } from 'react-router-dom';
import FormMain from '../../components/form-main/form-main';
import {useAppSelector} from '../../hooks/useAppSelector';
import {getAuthorizationStatus} from '../../store/user-process/user-process';
import { AuthorizationStatus } from '../../const';
import {toast} from 'react-toastify';
import { useEffect } from 'react';
import './entrance.css';
import { useAppDispatch } from '../../hooks/useAppDispatch';
import {
  setLocation,
  setTransistionStage,
  getLocation,
  getTransistionStage
} from '../../store/voting-process/voting-process';

export default function Entrance(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const displayLocation = useAppSelector(getLocation);
  const statusAuthorization = useAppSelector(getAuthorizationStatus);
  const transistionStage = useAppSelector(getTransistionStage);

  useEffect(() => {
    dispatch(setTransistionStage('fadeIn'))
    if (location.pathname !== displayLocation && statusAuthorization === AuthorizationStatus.Auth ) dispatch(setTransistionStage('fadeOut'));

    if (statusAuthorization === AuthorizationStatus.Auth ) {
      toast.info('Добро пожаловать');
    }

    return () => {
      dispatch(setTransistionStage('fadeOut'));
    };

  },[statusAuthorization, dispatch, location, displayLocation]);

  const handleChangeTransition = () => {
    if (transistionStage === 'fadeOut') {
      setTransistionStage('fadeIn');
      dispatch(setLocation(location.pathname));
    }
  }

  return (
    statusAuthorization !== AuthorizationStatus.Auth ?
      <main onAnimationEnd={handleChangeTransition} className={`main-entrance ${transistionStage}`}>
        <Helmet>
          <title>VOTE</title>
        </Helmet>

        <section className="entrance">
          <h2 className="visually-hidden">вход в систему</h2>
          <div className="container">
            <div className="entrance__wrapper">
              <p className="entrance__title">введите логин и&nbsp;пароль для&nbsp;входа&nbsp;в&nbsp;систему</p>
              <FormMain />
            </div>
          </div>
        </section>
      </main> : <Navigate to='/' />
  );
}
