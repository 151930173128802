
import * as S from './table.styled';

const Table = ({children, ...props}) => (
  <S.Table {...props}>
    <S.TBody>
      {children}
    </S.TBody>
  </S.Table>
);

const Tr = ({children, ...props}) => (
 <S.Tr {...props}>{children}</S.Tr>
);

const Td = ({children, ...props}) => (
  <S.Td {...props}>{children}</S.Td>
 );

 const Th = ({children, ...props}) => (
  <S.Th {...props}>{children}</S.Th>
 );

export {
  Table,
  Tr,
  Td,
  Th,
}
