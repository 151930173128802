export const months = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];

export const BACKEND_URL = 'https://vote-system.ru';
export const REQUEST_TIMEOUT = 5000;
export const TIMEOUT_SHOW_ERROR = 2000;

export enum AuthorizationStatus {
  Auth = 'AUTH',
  NoAuth = 'NO_AUTH',
  Unknown = 'UNKNOWN',
}

export enum SubmitStatus {
  Still = '...',
  Pending = 'Отправляется',
  Fullfilled = 'Успешно отправлено',
  Rejected = 'Запрос прерван',
}

export const DIRECTIONS = [
  'all',
  'Администрация',
  'Вузы, Ссузы',
  'Депутатский корпус',
  'ЖКХ',
  'Культура',
  'МФЦ',
  'Образование',
  'Почта России',
  'Правоохранители',
  'Соц.сфера',
  'Спорт ',
  'Строительство',
  'Транспорт',
];

export enum AppRoute {
  Main = '/main',
  Root = '/',
}

export enum NameSpace {
  User = 'USER',
  App = 'APP',
}

export enum UserType {
  Admin = 'pro',
  Regular = 'regular'
}
