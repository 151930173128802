import styled from 'styled-components';

const StyledInput = styled.input.attrs({type: 'text'})`
  font-size: 1em;
  border: 1px solid;
  border-color: ${({ theme }) => theme.color.grayLight};
  flex: 1 0 10%;
  user-select: none;
  padding: 19px 5px 19px 19px;
  outline: none;
  transition: border-color 0.3s;
  min-width: 205px;

  &:hover:not(:disabled):not(:focus) {
    border-color: ${({ theme }) => theme.color.gray};
  }

  &:focus {
    outline: 2px solid ${({ theme }) => theme.color.nepal};
    border: 1px solid ${({ theme }) => theme.color.nepal};
  }

  &:disabled {
    opacity: 0.2;
  }

  &::placeholder {
    text-transform: uppercase;
    color: ${({ theme }) => theme.color.nepal};
  }

  &:nth-child(7) {
    flex: 1 0 20%;
  }

  @media (max-width: 767px) {
    order: 1;
  }
`;

const PasswordInput = styled(StyledInput).attrs({
  type: "password",
})`
  border: 2px solid aqua;
`;

export {
  StyledInput,
  PasswordInput,
};

