import { Helmet } from 'react-helmet-async';
import { useState, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import * as S from './main.styled';
import MainLayout from '../../components/common/main-layout/main-layout';
import Container from '../../components/common/container/container';
import MessageBlock from '../../components/common/message-block/message-block';
import { Table, Tr, Td}  from '../../components/common/table/table';
import {ButtonLockForm, ButtonSmall, ButtonIcon, ButtonSmallGreen} from '../../components/common/button/button';
import FormAddVoter from '../../components/form-add-voter/form-add-voter';
import { CustomSelect } from '../../utils/select/custom-select';
import { ControlBox, ControlBoxTable, ControlBoxButton} from '../../components/common/control/control';
import Loading from '../../components/loading/loading';
import MainTable from '../../components/main-table/main-table';

import { CountByDirection } from '../../types';
import {
  UserType,
  SubmitStatus,
  months,
  AuthorizationStatus
} from '../../const';

import { useAppDispatch } from '../../hooks/useAppDispatch';
import { useAppSelector } from '../../hooks/useAppSelector';

import {
  getIsActiveForm,
  getIdActiveForm,
  getIsVotersLoading,
  getIsVoterSendingStatus,
  getVoters,
  getLocation,
  setLocation,
  getTransistionStage,
  setTransistionStage
} from '../../store/voting-process/voting-process';
import {
  fetchVoters,
  changeCurrentStatusVoters,
  changeStatusForm,
 } from '../../store/api-action';

import {
  getUser,
  getAuthorizationStatus,
} from '../../store/user-process/user-process';

import TableToExcell from '../../utils/utils';
import socket from '../../components/socket/socket';

// import { date } from 'faker/locale/zh_CN';

export default function Main(): JSX.Element {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const displayLocation = useAppSelector(getLocation);
  const transistionStage = useAppSelector(getTransistionStage);
  const isActiveForm = useAppSelector(getIsActiveForm);
  const idActiveForm = useAppSelector(getIdActiveForm);
  const isVotersLoading = useAppSelector(getIsVotersLoading);
  const isVoterSendingStatus = useAppSelector(getIsVoterSendingStatus);
  const statusAuthorization = useAppSelector(getAuthorizationStatus);
  const user = useAppSelector(getUser);
  const voters = useAppSelector(getVoters);

  const [activeList, setActiveList] = useState<boolean>(false);
  const [votersCountByDirection, setVotersCountByDirection] = useState<CountByDirection>({});

  const select = new CustomSelect();
  select.init();

  useEffect(() => {
    dispatch(setTransistionStage('fadeIn'))
    if (location.pathname !== displayLocation) dispatch(setTransistionStage('fadeOut'));

    if (statusAuthorization === AuthorizationStatus.Auth ) {
      try {
        socket.on('connect', () => {
          console.log(`${socket.id} connected is - ${socket.connected}`);
        });
      } catch (error) {
        console.error(error);
      }
    }
    return () => {
      socket.disconnect();
    };
  },[statusAuthorization, location, displayLocation, dispatch]);

  const handleShowAnalysisList = () => {
    setActiveList(!activeList);
  }

  const handleBlockingForm = (): void => {
    dispatch(changeStatusForm(idActiveForm));
    socket.emit('formChange', idActiveForm);
  }

  const handleChangeActiveVoters = () => {
    dispatch(changeCurrentStatusVoters());
    socket.emit('eventChangeDate', 'regular');
  }

  const handleDownloadAnalyzeTheDay = () => {
    const tableAnalys = new TableToExcell('analys-table', `${months[new Date().getMonth()]}-${new Date().getDate()}-анализ`);
    tableAnalys.init();
  }

  const handleDownloadDate = () => {
    const tableDate = new TableToExcell('date-table', `${months[new Date().getMonth()]}-${new Date().getDate()}-все`);
    tableDate.init();
  }

  const handleChangeTransition = () => {
    if (transistionStage === 'fadeOut') {
      setTransistionStage('fadeIn');
      setLocation(location.pathname);
    }
  }

  const handleAnalyzeTheDay = () => {
    dispatch(fetchVoters());
    const filteredVoters = voters.filter((voter) => voter.isCurrent);
    const countByDirection = filteredVoters.reduce((acc, obj) => {
      const direction = obj.direction;
      acc[direction] = (acc[direction] || 0) + 1;
      return acc;
    }, {} as CountByDirection);
    setVotersCountByDirection(countByDirection);
  }

  // const handleTimer = (evt: ChangeEvent<HTMLDivElement>) => {
  //   function startTimer(duration: number, display) {
  //       let timer: string = duration, minutes, seconds;
  //       let intervalId = setInterval(function () {
  //           minutes = parseInt(timer / 60, 10);
  //           seconds = parseInt(timer % 60, 10);

  //           minutes = minutes < 10 ? '0' + minutes : minutes;
  //           seconds = seconds < 10 ? '0' + seconds : seconds;

  //           display.textContent = minutes + ':' + seconds;

  //           if (--timer < 0) {
  //               clearInterval(intervalId);
  //               alert('Время вышло!');
  //           }
  //       }, 1000);
  //   }

  //   window.onload = function () {
  //       let fiveMinutes = 60 * 5,
  //       display = document.querySelector('#timer');
  //       startTimer(fiveMinutes, display);
  //   };
  // };

  // const { pathname } = useLocation() as { pathname: AppRoute };

  // const RootClassName: Record<AppRoute, string> = {
  //   [AppRoute.Root]: 'page--gray page--main',
  //   [AppRoute.Login]: 'page--gray page--login',
  //   [AppRoute.Register]: 'page--gray page--login',
  //   [AppRoute.Favorites]: favoriteOffers.length === 0 ? 'page--favorites-empty' : '',
  //   [AppRoute.Property]: '',
  //   [AppRoute.Add]: '',
  //   [AppRoute.Edit]: '',
  //   [AppRoute.NotFound]: '',
  // };

  // <div className={`page ${RootClassName[pathname]}`}>


  return (
    statusAuthorization ? <MainLayout>
      <Helmet>
        <title>URT-voitt</title>
      </Helmet>

      <S.Main onAnimationEnd={handleChangeTransition} forwardedAs="main" className={`${!isActiveForm ? `is-disabled ${transistionStage}` : `${transistionStage}`}`}>
        <Container>
          <FormAddVoter/>
        </Container>
        <MessageBlock className={isVoterSendingStatus === SubmitStatus.Fullfilled ? "is-successfully" : ""}>
          {`${!isActiveForm ? "Форма временно заблокирована" : isVoterSendingStatus}`}
          <p id="timer"></p>
        </MessageBlock>
        {user && user.type === UserType.Admin && <Container>
          <ControlBox $margin="10px 10px 0 10px">
            <ButtonIcon className={!activeList ? "" : "is-active"} onClick={handleShowAnalysisList} type="button"></ButtonIcon>
            <ButtonLockForm onClick={handleBlockingForm} type="button">{`${isActiveForm ? "блокировка формы" : "разблокировать форму"}`}</ButtonLockForm>
          </ControlBox>
        </Container> }
        {user && user.type === UserType.Admin && <Container className={!activeList ? "visually-hidden" : ""} style={{borderBottom: "10px solid #96b8cc"}}>
          <ControlBoxTable $margin="10px 0">
            <Table id="analys-table" style={{width: "20%", margin: "0", padding: "0"}}>
              {!isVotersLoading && !(Object.keys(votersCountByDirection).length === 0) ? Object.entries(votersCountByDirection).map(([key, val]) =>
              {
                return (
                <Tr key={key} style={{padding: "0"}}>
                  <Td>{key.toString()}</Td>
                  <Td>{val}</Td>
                </Tr>);
              }).slice(0) : <Tr><Td>Загрузите анализ дня</Td></Tr>}
            </Table>
            <ControlBoxButton>
              <ButtonSmall onClick={handleAnalyzeTheDay} type="button">Анализ за сутки</ButtonSmall>
              <ButtonSmallGreen onClick={handleDownloadAnalyzeTheDay} id="analys-button" type="button">Скачать анализ</ButtonSmallGreen>
              <ButtonSmall onClick={handleDownloadDate} id="date-button" type="button">Скачать данные</ButtonSmall>
              <ButtonSmall onClick={handleChangeActiveVoters} disabled={isActiveForm} type="button">Регистрация суточных данных</ButtonSmall>
            </ControlBoxButton>
          </ControlBoxTable>
        </Container>}
        <Container className="container" $mt="10px" $mh={!activeList ? "430px": "230px"} $overflow="auto">
          {!isVotersLoading ? <MainTable/> : <Loading/>}
        </Container>
      </S.Main>
    </MainLayout> : <Navigate to='/entrance' />
  );
}
