import ReactDOM from 'react-dom/client';
import App from './components/app/app';
import {Provider} from 'react-redux';
import {store} from './store';
// import styles from './index.module.scss';
// import './index.scss';

import {ToastContainer} from 'react-toastify';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Provider store = {store}>
    <ToastContainer
      position='top-center'
      theme='colored'
      autoClose={2000}
    />
    <App />
  </Provider>
);
