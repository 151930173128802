import {
  TVoter,
  TVoterFormOutput,
  TUser,
  VoterDto,
  UserWithTokenDto,
  UserDto,
  UserRegister,
  TSubmitUser,
  TIsActiveForm,
} from '../types';
import { setSortDirection } from './voting-process/voting-process';
import type { History } from 'history';
import type { AxiosError, AxiosInstance } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { Token, Direction } from '../utils/utils';

type ThunkApiConfig = {
  api: AxiosInstance;
  browserHistory: History;
}

export const fetchUserStatus = createAsyncThunk<TUser, undefined,  { extra: ThunkApiConfig }>(
  'user/fetchUserStatus',
  async (_, { extra, dispatch }) => {
    const { api } = extra;

    try {
      const { data: user } = await api.get<UserWithTokenDto>('/users/');
      dispatch(setSortDirection(user.direction));
      dispatch(fetchVotersByDirection(user.direction));
      return user;
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 401) {
        Token.drop();
      }
      Token.drop();

      return Promise.reject(error);
    }
  },
);

export const loginUser = createAsyncThunk<TUser, TSubmitUser, { extra: ThunkApiConfig }>(
  'user/loginUser',
  async ({email, password}, { extra, dispatch }) => {
    const { api } = extra;
    const {data: user, status} = await api.post<UserWithTokenDto>('/users/login', {email, password});

    if (status === 200) {
      user.token && Token.save(user.token);
      user.direction && Direction.save(user.direction);
      dispatch(setSortDirection(user.direction));
      dispatch(fetchVotersByDirection(user.direction));
    }
    return user;
  },
);

export const logoutUser = createAsyncThunk<void, undefined, { extra: ThunkApiConfig }>(
  'user/logoutUser',
  async (_, { extra }) => {
    await extra.api.delete<void>('/users/logout');
    Token.drop();
    Direction.drop();
  });

  export const registerUser = createAsyncThunk<void, UserRegister, { extra: ThunkApiConfig }>(
    'user/registerUser',
    async ({ email, password, type, direction }, { dispatch, extra }) => {
      const { api, browserHistory } = extra;

      const { status } = await api.post<UserDto>('/users/register', {email, password, type, direction});

      if (status === 201) {
        const action = await dispatch(loginUser({ email, password }));

        if (action.type === loginUser.fulfilled.type) {
          await dispatch(fetchUserStatus());
        }

        browserHistory.push('/');
      }
    });

export const fetchVoters = createAsyncThunk<TVoter[], undefined, { extra: ThunkApiConfig }>(
  'app/fetchVoters',
  async (_, { extra, dispatch }) => {
    const { api } = extra;
    const {data} = await api.get<TVoter[]>('/voters');
    await dispatch(fetchStatusForm());
    return data;
  },
);

export const fetchVotersByDirection = createAsyncThunk<TVoter[], string, { extra: ThunkApiConfig }>(
  'app/fetchVotersByDirection',
  async (direction, { extra, dispatch }) => {
    const { api } = extra;
    const {data} = await api.get<TVoter[]>(`/voters/${direction}`);
    await dispatch(fetchStatusForm());
    return data;
  },
);

export const fetchVoter = createAsyncThunk<TVoter, TVoter['id'], { extra: ThunkApiConfig }>(
  'app/fetchVoter',
  async (id, { extra }) => {
    const { api, browserHistory } = extra;

    try {
      const {data} = await api.get<TVoter>(`/voters/${id}`);
      return data;
    } catch (error) {
      const axiosError = error as AxiosError;

      if (axiosError.response?.status === 404) {
        browserHistory.push('*');
      }

      return Promise.reject(error);
    }
  },
);

export const postVoter = createAsyncThunk<TVoter, TVoterFormOutput, { extra: ThunkApiConfig }>(
  'app/postVoter',
  async (voter, { extra, dispatch }) => {
    const { api, browserHistory } = extra;
    const { data: newVoter} = await api.post<VoterDto>('/voters', voter);
    const direction = Direction.get();
    await dispatch(fetchVotersByDirection(direction));
    browserHistory.push('/');
    return newVoter;
  }
);

export const deleteVoter = createAsyncThunk<void, TVoter['id'], { extra: ThunkApiConfig }>
('app/deleteVoter',
  async (id, { extra, dispatch }) => {
    const { api, browserHistory } = extra;
    const {data} = await api.delete<void>(`/voters/${id}`);
    await dispatch(fetchVoters());
    browserHistory.push('/');
    return data;
  },
);

export const fetchStatusForm = createAsyncThunk<TIsActiveForm[], undefined, { extra: ThunkApiConfig }>
('app/fetchStatusForm',
  async (_, { extra } ) => {
    const { api } = extra;
    const {data} = await api.get<TIsActiveForm[]>('/form');
    return data;
  },
);

export const changeStatusForm = createAsyncThunk<TIsActiveForm[], TIsActiveForm['_id'], { extra: ThunkApiConfig }>
('app/postStatusForm',
  async (_id, { extra, dispatch  } ) => {
    const { api, browserHistory } = extra;
    await api.patch<void>(`/form/${_id}`);
    const {data: newData} = await api.get<TIsActiveForm[]>('/form');
    const direction = Direction.get();
    await dispatch(fetchVotersByDirection(direction));
    browserHistory.push('/');
    return newData;
  },
);

export const changeCurrentStatusVoters = createAsyncThunk<void, undefined, { extra: ThunkApiConfig }>
('app/changeCurrentStatusVoters',
  async (_, { extra, dispatch } ) => {
    const { api, browserHistory } = extra;

    await api.put<void>('voters');
    await dispatch(fetchVoters());
    browserHistory.push('/');
  },
);
