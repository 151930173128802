import styled from 'styled-components';

const StyledFooter = styled.footer`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FooterFirstBox = styled.div`
  position: relative;
  margin: 0;
  padding: 20px 0 10px;
  text-align: center;
  background-color: ${({ theme }) => theme.color.chambrayLight};

  &::before {
    position: absolute;
    display: block;
    left: 0;
    top: 0;
    content: "";
    width: 100%;
    height: 12px;
    background-color: ${({ theme }) => theme.color.nepal};
  }
`;

const FooterSecondBox = styled.div`
  padding: 20px 0;
  text-align: center;

  background-image: linear-gradient(
      0.36deg,
      ${({ theme }) => theme.color.black} 5%,
      ${({ theme }) => theme.color.chambrayLight} 98%
    );
`;

const FooterText = styled.p`
  margin: 0;
`;
// const HiddenText = styled.span.attrs({ className: 'visually-hidden' })``;

export {
  StyledFooter,
  FooterFirstBox,
  FooterSecondBox,
  FooterText,
};
